import { ClientEnv } from 'constants/Chat.constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';

import { resetChat } from 'actions';
import api from 'api';
import { HTTPError } from 'api/error';
import { ProjectWithTokenExceeded } from 'api/types';
import { setClientEnv } from 'ducks/chatPanel/chatPanelSlice';
import { getProjectQueryKey } from 'utils/query';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import useSampleProject from './useSampleProject';
import { useToast } from './useToast';

export const PROJECT_MUTATION_KEY = 'project';

const useProject = () => {
  const { accountId, projectId } = useRequiredParams<{
    accountId: string;
    projectId: string;
  }>();
  const { inSampleProject } = useSampleProject();

  const dispatch = useAppDispatch();
  const clientEnv = useAppSelector((state) => state.chatPanel.clientEnv);

  const queryKey = getProjectQueryKey(accountId, projectId);
  const queryClient = useQueryClient();

  const toast = useToast();

  const {
    data: project,
    isLoading: isLoadingProject,
    isError: isProjectError,
    error: projectError,
  } = useQuery<ProjectWithTokenExceeded, HTTPError, ProjectWithTokenExceeded>({
    queryKey,
    queryFn: () => api.getProject(accountId, projectId),
    enabled: !!accountId && !!projectId && !inSampleProject,
  });

  const resetChats = () => {
    if (clientEnv === ClientEnv.DRAFT) {
      dispatch(setClientEnv(ClientEnv.SANDBOX));
    }
    Object.values(ClientEnv).forEach((value) => dispatch(resetChat(value)));
  };

  const { mutate: publishDraft, isLoading: isPublishingDraft } = useMutation(
    (deploymentMessage: string) =>
      api.publishDraft(accountId, projectId, deploymentMessage),
    {
      mutationKey: [PROJECT_MUTATION_KEY],
      onSuccess: () => {
        queryClient
          .refetchQueries([accountId, projectId])
          .catch((e) =>
            console.error('Error occurred. Could not refetch queries', e),
          );
        resetChats();
        toast.success({
          title: 'Assistant published',
        });
      },
      onError: () => toast.error({ title: 'Failed to publish draft' }),
    },
  );

  const { mutate: deleteDraft, isLoading: isDeletingDraft } = useMutation(
    () => api.deleteDraft(accountId, projectId),
    {
      mutationKey: [PROJECT_MUTATION_KEY],
      onSuccess: () => {
        queryClient
          .invalidateQueries([accountId, projectId])
          .catch((e) =>
            console.error('Error occurred. Could not invalidate queries', e),
          );
        resetChats();
        toast.success({
          title: 'Draft deleted',
        });
      },
    },
  );

  return {
    project,
    isLoadingProject,
    isProjectError,
    isProjectForbidden: projectError?.code === 'UserAccessForbidden',
    projectQueryKey: queryKey,
    accountId,
    projectId,
    publishDraft,
    isPublishingDraft,
    deleteDraft,
    isDeletingDraft,
  };
};

export default useProject;
