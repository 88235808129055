import { useMemo, useRef } from 'react';

import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { ComponentWithChildren } from '@polyai/common/types/helpers';

import { setSavedState } from 'ducks/layout/layoutSlice';
import { useToast } from 'hooks/useToast';
import { HTTPError } from './error';
import { handleGlobalErrors } from './response';

interface PolyQueryMeta {}

interface PolyMutationMeta {
  isDraftChange: boolean;
}

declare module '@tanstack/react-query' {
  interface QueryMeta extends PolyQueryMeta {}
  interface MutationMeta extends PolyMutationMeta {}
}

const CustomQueryClientProvider: ComponentWithChildren = ({ children }) => {
  const dispatch = useDispatch();
  const numMutations = useRef(0);
  const savedTimeout = useRef<NodeJS.Timeout | undefined>();

  const toast = useToast();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onMutate: (_, mutation) => {
            if (mutation.meta?.isDraftChange) {
              if (!numMutations.current) {
                dispatch(setSavedState('saving'));
              }
              numMutations.current += 1;
            }
          },
          onSuccess: (_, __, ___, mutation) => {
            if (mutation.meta?.isDraftChange) {
              dispatch(setSavedState('saved'));
              clearTimeout(savedTimeout.current);
              savedTimeout.current = setTimeout(() => {
                dispatch(
                  setSavedState(0 < numMutations.current ? 'saving' : 'idle'),
                );
                savedTimeout.current = undefined;
              }, 3000);
            }
          },
          onError: (error, __, ___, mutation) => {
            handleGlobalErrors(error as HTTPError, mutation, toast);

            if (mutation.meta?.isDraftChange) {
              if (savedTimeout.current) {
                return;
              }
              dispatch(
                setSavedState(1 < numMutations.current ? 'saving' : 'idle'),
              );
            }
          },
          onSettled: (_, __, ___, ____, mutation) => {
            if (mutation.meta?.isDraftChange) {
              numMutations.current = Math.max(0, numMutations.current - 1);
            }
          },
        }),
        defaultOptions: {
          queries: {
            cacheTime: 300000,
            staleTime: 60000,
            retry: false,
          },
        },
      }),
    // adding toast as dep causes querying issues
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default CustomQueryClientProvider;
