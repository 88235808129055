import { FC } from 'react';

import {
  SUPPORT_EMAIL,
  SUPPORT_SUBJECT,
  SUPPORT_TEXT,
  SUPPORT_TITLE,
} from 'constants/EmailUs.constants';

import IconButton from '@polyai/ui/components/atoms/IconButton';
import IconFrame from '@polyai/ui/components/atoms/IconFrame';
import { CopyIcon } from '@polyai/ui/components/atoms/Icons';
import { Tooltip } from '@polyai/ui/components/atoms/Tooltip';
import { ConfirmationModal } from '@polyai/ui/components/molecules/ConfirmationModal';
import { useBreakpoint } from '@polyai/ui/hooks/useBreakpoint';

import * as Styled from './EmailUsModal.styled';

interface EmailUsModalProps {
  trigger: JSX.Element;
  title?: string;
  email?: string;
  description?: string;
  subject?: string;
  body?: string;
  closeBtnText?: string;
}

const EmailUsModal: FC<EmailUsModalProps> = ({
  trigger,
  title = SUPPORT_TITLE,
  email = SUPPORT_EMAIL,
  description = SUPPORT_TEXT,
  subject = SUPPORT_SUBJECT,
  body = '',
  closeBtnText = 'Close',
}) => {
  const isNonMobile = useBreakpoint('xs');
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(email);
    } catch (e) {
      console.error('Error occurred. Could not copy email.', e);
    }
  };

  return (
    <ConfirmationModal
      buttonText={closeBtnText}
      confirmButtonVariant="SECONDARY"
      content={
        <>
          <Styled.Description>{description}</Styled.Description>
          <Styled.EmailWrapper>
            <Styled.EmailContent
              to={`mailto:${email}?subject=${subject}&body=${body}`}
            >
              <IconFrame
                backgroundColor="borderSubtle"
                color="iconSecondary"
                size="xxl"
                type="MailIcon"
              />
              <Styled.Email>{email}</Styled.Email>
            </Styled.EmailContent>
            <Tooltip
              on={['click']}
              position={isNonMobile ? 'bottom center' : 'left center'}
              trigger={
                <IconButton
                  hoverTargetColor="borderSubtle"
                  hoverTarget
                  onClick={handleCopy}
                >
                  <CopyIcon color="iconSecondary" />
                </IconButton>
              }
            >
              Copied to clipboard
            </Tooltip>
          </Styled.EmailWrapper>
        </>
      }
      hasCancelButton={false}
      size="md"
      title={title}
      trigger={trigger}
    />
  );
};

export default EmailUsModal;
