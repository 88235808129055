import React, {
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { PopupActions } from "reactjs-popup/dist/types";

import Button, { ButtonVariant } from "components/atoms/Button";
import { IconButton } from "components/atoms/IconButton";
import { CloseIcon } from "components/atoms/Icons";
import { BaseIcon } from "components/atoms/Icons/BaseIcon";
import Modal, { ModalProps } from "components/molecules/Modal";

import * as Styled from "./ConfirmationModal.styled";

export interface ConfirmationModalType extends Omit<ModalProps, "children"> {
  title?: string | JSX.Element;
  content?: string | JSX.Element;
  onConfirm?: () => void;
  buttonText?: string;
  cancelText?: string;
  confirmButtonVariant?: keyof typeof ButtonVariant;
  onClose?: () => void;
  hasCloseIcon?: boolean;
  confirmDisabled?: boolean;
  enableLoading?: boolean;
  confirmRequesting?: boolean;
  className?: string;
  contentStyle?: any;
  disableConfirmOnEnter?: boolean;
  hasCancelButton?: boolean;
  closeOnEscape?: boolean;
  onConfirmDDActionName?: string;
  when?: boolean;
  skipCloseAndConfirm?: boolean;
  onCancelButtonClick?: () => void;
  onConfirmButtonClick?: () => void;
  confirmButtonIcon?: typeof BaseIcon;
  confirmButtonIconPlacement?: "left" | "right" | "both";
}

export const ConfirmationModal: React.FC<ConfirmationModalType> = React.memo(
  ({
    trigger,
    title,
    content,
    onConfirm,
    buttonText = "Confirm",
    cancelText = "Cancel",
    confirmButtonVariant = "PRIMARY",
    onClose,
    onOpen,
    className,
    onConfirmDDActionName,
    hasCloseIcon = false,
    hasCancelButton = true,
    confirmDisabled = false,
    enableLoading = false,
    confirmRequesting = false,
    disableConfirmOnEnter = false,
    closeOnEscape = true,
    size = "sm",
    when = true,
    skipCloseAndConfirm = false,
    onCancelButtonClick,
    onConfirmButtonClick,
    confirmButtonIcon,
    confirmButtonIconPlacement,
    ...props
  }: ConfirmationModalType) => {
    const [isOpen, setIsOpen] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const popupRef = useRef<PopupActions>(null);
    const close = popupRef.current?.close;
    const confirmAndClose = useCallback(
      (
        event: KeyboardEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        event.preventDefault();
        event.stopPropagation();
        if (disableConfirmOnEnter && (event as KeyboardEvent).key === "Enter") {
          return;
        }
        setConfirmed(true);
        onConfirm?.();
        if (!enableLoading) {
          close?.();
        }
      },
      [disableConfirmOnEnter, onConfirm, enableLoading, close]
    );

    const onKeyDown = useCallback(
      (event: KeyboardEvent) => {
        event.key === "Enter" && isOpen && confirmAndClose(event);
      },
      [isOpen, confirmAndClose]
    );

    useEffect(() => {
      if (confirmed && !confirmRequesting) {
        close?.();
        setConfirmed(false); // reset state
      }
    }, [close, confirmRequesting, confirmed]);

    useEffect(() => {
      window.addEventListener("keydown", onKeyDown);
      return () => window.removeEventListener("keydown", onKeyDown);
    }, [onKeyDown]);

    const onOpenHandler = useCallback(() => {
      setIsOpen(true);
      onOpen?.();
    }, [onOpen]);

    const onCloseHandler = useCallback(() => {
      setIsOpen(false);
      onClose?.();
    }, [onClose]);

    if (!when && trigger && React.isValidElement(trigger)) {
      return cloneElement(trigger, { onClick: onConfirm } as any);
    }

    return (
      <Modal
        ref={popupRef}
        className={className}
        closeOnEscape={closeOnEscape}
        size={size}
        trigger={trigger}
        onClose={onCloseHandler}
        onOpen={onOpenHandler}
        {...props}
      >
        <Styled.ModalWrapper data-test-id="confirmation-modal">
          <Styled.HeaderWrapper>
            {title ? (
              <Styled.ModalTitle as="h2">{title}</Styled.ModalTitle>
            ) : null}
            {hasCloseIcon && (
              <IconButton onClick={close}>
                <CloseIcon color="iconSecondary" />
              </IconButton>
            )}
          </Styled.HeaderWrapper>
          {content && <Styled.ModalContent>{content}</Styled.ModalContent>}

          <Styled.Actions $hasCancelButton={hasCancelButton}>
            {hasCancelButton && (
              <Button
                disabled={confirmRequesting}
                label={cancelText}
                variant="SECONDARY"
                onClick={() => {
                  onCancelButtonClick?.();
                  close?.();
                }}
              />
            )}

            <Button
              Icon={confirmButtonIcon}
              data-dd-action={onConfirmDDActionName}
              data-test-id={`popup-button-${buttonText}`}
              disabled={confirmDisabled}
              iconPlacement={confirmButtonIconPlacement}
              label={buttonText}
              loading={confirmRequesting}
              variant={confirmButtonVariant}
              onClick={(e) => {
                onConfirmButtonClick?.();
                if (!skipCloseAndConfirm) confirmAndClose?.(e);
              }}
            />
          </Styled.Actions>
        </Styled.ModalWrapper>
      </Modal>
    );
  }
);
